import axios from "axios";

/**
 * 获取量产排班主界面表格数据
 */
export const fetchOutputDispatchList = paramObj => {
  return axios({
    url: "/dtsum/zongtong/order/ProductExecuteController/massDispatchInfo",
    method: "GET",
    params: { param: paramObj }
  });
};

/**
 * 获取排班详情
 */
export const fetchDispatchDetail = paramObj => {
  return axios({
    url: "/dtsum/zongtong/order/ProductExecuteController/clickDispatchDetails",
    method: "GET",
    params: { param: paramObj }
  });
};

/**
 * 获取所有挡车工的数据列表
 */
export const fetchAllWorkerList = () => {
  return axios({
    url: "/dtsum/zongtong/order/ProductExecuteController/findWorkerNameList",
    method: "GET"
  });
};

/**
 * 获取生产机台列表，机台弹窗中展示   ps：这个接口在 语雀接口文档——纵通1.2web端 里面
 */
export const fetchDeviceList = paramObj => {
  return axios({
    url: "/dtsum/zongtong/order/ProductPlanController/deviceList",
    method: "GET",
    params: { param: paramObj }
  });
};

/**
 * 新增/修改 量产排班
 */
export const fetchAddDispatch = data => {
  return axios({
    url: "/dtsum/zongtong/order/ProductExecuteController/clickDispatchConfirm",
    method: "POST",
    data
  });
};

/**
 * 取消 量产排班
 * @param {number} ztdwId 量产派工表ID
 */
export const fetchCancelDispatch = ztdwZtuid => {
  return axios({
    url: "/dtsum/zongtong/order/ProductExecuteController/cancelDispatchWork",
    method: "GET",
    params: { ztdwZtuid }
  });
};

/**
 * 修改备注
 */
 export const fetchNote = data => {
  return axios({
    url: "/dtsum/zongtong/applets/ScheduleController/saveWebScheduleRemarksInfo",
    method: "POST",
    data
  });
};

<template>
  <div id="page-device-view">
    <!-- 筛选区块 -->
    <div class="common-filter-block">
      <div class="common-filter-item">
        <span style="margin-right: 10px">量产日期</span>
        <DatePicker
          type="date"
          placeholder="请选择日期"
          :clearable="false"
          v-model="listParam.productDate"
          @on-change="getTableListData"
        />
      </div>
      <div class="common-filter-item">
        <span style="margin-right: 10px">班次</span>
        <Select v-model="listParam.productShift" @on-change="getTableListData">
          <Option v-for="item in productShiftOptions" :value="item.value" :key="item.value">{{
            item.label
          }}</Option>
        </Select>
      </div>
    </div>

    <!-- 功能选项区块 -->
    <div class="common-action-block">
      <div class="common-action-item">
        <div
          class="dispatch-function"
          style="color: #256de6; cursor: pointer; margin-right: 10px"
          @click="clickAddDispatch"
        >
          <span class="icon iconfont iconicon-zhipai">&nbsp;&nbsp;</span>
          <span>排班</span>
        </div>
      </div>
    </div>

    <!-- 表格 -->
    <Table :border="true" :loading="loading" :columns="tableColumns" :data="tableData">
      <template slot-scope="{ row, index }" slot="action">
        <span
          style="color: #256de6; cursor: pointer; margin-right: 10px"
          @click="clickCancelDispatch(row, index)"
          >取消</span
        >
        <span style="color: #256de6; cursor: pointer" @click="clickModifyDispatch(row, index)"
          >修改</span
        >
      </template>
      <template slot-scope="{ row, index }" slot="edit">
        <span class="common-able-click" @click="clickEdit(row, index)" style="margin-right:15px">编辑</span>
        <span style="color:#EA3A3E">{{row.remarks}}</span>
      </template>
    </Table>

    <Modal
      v-model="isShowDispatchModal"
      title="排班计划"
      width="460"
      :transfer="false"
      class="dispatch-modal"
      @on-visible-change="(_) => dispatchVisibleChange(_, 'dispatchFormRef')"
    >
      <div slot="footer">
        <Button type="text" size="large" @click="isShowDispatchModal = false">取消</Button>
        <Button type="primary" size="large" @click="submitDispatch('dispatchFormRef')">确定</Button>
      </div>

      <Form
        :label-width="80"
        label-position="left"
        ref="dispatchFormRef"
        :model="dispatchFormObj"
        :rules="dipatchFormRule"
      >
        <FormItem label="量产日期" prop="productDate"
          ><DatePicker
            :options="datePickerOptions"
            :disabled="dispatchOperation === 2"
            v-model="dispatchFormObj.productDate"
            style="width: 100%"
        /></FormItem>
        <FormItem label="班次" prop="productShift">
          <Select
            :disabled="dispatchOperation === 2"
            v-model="dispatchFormObj.productShift"
            style="width: 100%"
          >
            <Option v-for="item in productShiftOptions" :value="item.value" :key="item.value">{{
              item.label
            }}</Option>
          </Select></FormItem
        >
        <FormItem label="挡车工" prop="ztdwztuid">
          <Select
            :disabled="dispatchOperation === 2"
            v-model="dispatchFormObj.ztdwztuid"
            style="width: 100%"
          >
            <Option v-for="item in allWorkerList" :value="item.userId" :key="item.userId">{{
              item.workerName
            }}</Option>
          </Select></FormItem
        >
        <FormItem label="机台号" prop="machineNo">
          <Tag
            closable
            size="large"
            v-for="item in dispatchFormObj.machineNo"
            :key="item"
            :name="item"
            @on-close="removeMachineTag"
            >{{ item }}</Tag
          >
          <Button icon="ios-add-circle-outline" type="text" @click="clickSelectMachine"
            >选择机台</Button
          ></FormItem
        >
      </Form>
    </Modal>

        <!-- 修改日期的弹窗 -->
    <Modal
      class="dispatch-modal"
      title="备注信息"
      v-model="isShowNoteModal"
      @on-ok="submitNote"
    >
     <Input v-model="remarks" type="textarea" :autosize="true" placeholder="请输入..."></Input>
    </Modal>

    <SelectMachineModal
      :is-show-modal="isShowMachineModal"
      :device-id-list="dispatchFormObj.machineNo"
      @visible-change="isShowMachineModal = $event"
      @device-id-list-change="dispatchFormObj.machineNo = $event"
    />
  </div>
</template>

<script>
import { productShiftOptions } from "./constant";
import {
  fetchOutputDispatchList,
  fetchDispatchDetail,
  fetchAddDispatch,
  fetchCancelDispatch,
  fetchAllWorkerList,
  fetchNote
} from "./api";
import SelectMachineModal from "./SelectMachineModal.vue";

export default {
  name: "OutputDispatch",
  components: {
    SelectMachineModal,
  },
  data() {
    return {
      remarksIndex: -1,
      remarks: "", // 备注信息
      datePickerOptions: {
        disabledDate(date) {
          return date && date.valueOf() < Date.now() - 86400000;
        },
      },

      productShiftOptions, // 排班班次的 Select 下拉框列表选项

      isShowNoteModal: false, // 备注弹框

      dispatchOperation: 1, // 记录用户执行的排班操作，1：新增排班，2：修改排班
      isShowDispatchModal: false, // 1级弹窗，排班
      isShowMachineModal: false, // 2级弹窗，设备机台
      allWorkerList: [], // 所有挡车工信息的数组，形如 [{ userId:111, workerName:"jack" }, ...]

      // 首页表格列表的请求参数
      listParam: {
        productDate: this.moment(new Date()).format("YYYY-MM-DD"),
        productShift: 1, // 1白班,2夜班,3白转夜，4夜转白
      },

      // 首页表格 配置
      loading: false,
      tableData: [],
      tableColumns: [
        {
          title: "序号",
          type: "index",
          width: 66,
        },
        {
          title: "操作",
          slot: "action",
          width: 120,
        },
        {
          title: "员工姓名",
          key: "workerName",
          width: 120,
        },
        {
          title: "机台数量",
          key: "machineNum",
          width: 120,
        },
        {
          title: "机台号",
          key: "machineNo",
          minWidth: 200,
        },
        {
          title: "备注",
          slot: "edit",
          minWidth: 200,
        },
      ],

      // 排班计划表单 配置
      dispatchFormObj: {
        productDate: "", // 量产日期，形如 "2021-07-16"
        productShift: null, // 1 白班，2 夜班，3 白转夜，4 夜转白
        ztdwztuid: null, // 员工（挡车工）Id，数值型
        machineNo: [], // 是数组，发请求时，需转成 "302#,304#,307#" 形式
      },
      dipatchFormRule: {
        productDate: [
          {
            type: "date",
            required: true,
            message: "量产日期不能为空",
          },
        ],
        productShift: [
          {
            required: true,
            message: "班次不能为空",
          },
        ],
        ztdwztuid: [
          {
            type: "number",
            required: true,
            message: "指派员工不能为空",
            trigger: "change",
          },
        ],
        machineNo: [
          {
            type: "array",
            required: true,
            message: "机台号不能为空",
            validator: (rule, value) => value.length > 0,
          },
        ],
      },
    };
  },
  watch: {},
  created() {
    this.getTableListData();
    this.getAllWorkerList();
  },
  methods: {
    // 首页表格数据获取
    getTableListData() {
      this.loading = true;
      this.listParam.productDate = this.moment(this.listParam.productDate).format("YYYY-MM-DD");
      fetchOutputDispatchList(this.listParam)
        .then((res) => {
          if (res.data.success === 1) {
            this.tableData = res.data.body.list;
            this.loading = false;
          } else {
            this.$Message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getAllWorkerList() {
      fetchAllWorkerList()
        .then((res) => {
          if (res.data.success === 1) {
            this.allWorkerList = res.data.body.workerNameList;
          } else {
            this.$Message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    dispatchVisibleChange(isVisible, formRef) {
      this.$refs[formRef].resetFields();

      // ####  本来想的是，每次新建操作的时候，打开该弹窗，保留上次新建操作的部分数据，这样就可以方便 多次新建 操作了，可以少交互几个字段
      this.dispatchFormObj = { ...this.dispatchFormObj, ztdwztuid: null, machineNo: [] }; 
    },

    clickAddDispatch() {
      this.dispatchOperation = 1; // 1：执行了新增排班操作，记录一下
      this.isShowDispatchModal = true;
    },

    clickModifyDispatch(row, index) {
      this.dispatchOperation = 2; // 2：执行了修改排班操作，记录一下
      this.isShowDispatchModal = true;

      const { userId } = row;
      const { productDate, productShift } = this.listParam;

      this.dispatchFormObj.ztdwztuid = userId;
      this.dispatchFormObj.productDate = this.moment(productDate).format("YYYY-MM-DD");
      this.dispatchFormObj.productShift = productShift;

      this.getDispatchDetail();
    },

    // 点击编辑备注
    clickEdit(row, index) {
      console.log(index);
      this.isShowNoteModal = true;
      this.remarksIndex = index; // 记录一下当前操作的行的index   
      this.remarks = this.tableData[index].remarks;
      this.newArr = this.tableData;
      this.newArr[index].remarks = this.remarks
    },

    getDispatchDetail() {
      let { productDate, ztdwztuid, productShift } = this.dispatchFormObj;

      productDate = this.moment(productDate).format("YYYY-MM-DD");
      fetchDispatchDetail({ userId: ztdwztuid, productDate, productShift })
        .then((res) => {
          if (res.data.success === 1) {
            const { machineNo } = res.data.body.queryDispatchDetailsInfo;

            this.dispatchFormObj = {
              machineNo: machineNo !== "" ? machineNo.split(",") : [],
              ztdwztuid,
              productDate,
              productShift,
            };
          } else {
            this.$Message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    clickCancelDispatch(row, index) {
      this.dispatchOperation = 3; // 3：执行了删除排班操作，记录一下
      this.$Modal.confirm({
        title: "是否取消排班？",
        onOk: () => {
          fetchCancelDispatch(row.userId)
            .then((res) => {
              if (res.data.success === 1) {
                this.$Message.success("取消排班成功！");
                this.tableData.splice(index, 1); // 删除 tableData 数组的对应下标项
              } else {
                this.$Message.error(res.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        },
      });
    },

    // 新增/修改 排班的提交
    submitDispatch(formRef) {
      console.log(111); // 进入了 submitDispatch 方法

      const { productDate, productShift, ztdwztuid, machineNo } = this.dispatchFormObj;
      const questParam = {
        ztdwDate: this.moment(productDate).format("YYYY-MM-DD"),
        ztdwWorkTime: productShift,
        ztdwztuid,
        ztdwDeviceNo: machineNo.join(","),
      };

      // this.$refs.dispatchFormRef.validate((valid) => {
      //   console.log("进来了");

      //   if (valid) {
      //     console.log("aaaaaaaaaaaaaaaaa");
      //   } else {
      //     console.log("bbbbbbbbbbbbbbbbbbbbb");
      //   }
      // });

      console.log(222); // 进入了 submitDispatch 方法

      this.$refs[formRef].validate((valid) => {
        console.log(" aaa "); //  发送 fetchAddDispatch 请求前

        if (valid) {
          console.log(" okkkk "); //  发送 fetchAddDispatch 请求前

          console.log(222); //  发送 fetchAddDispatch 请求前

          fetchAddDispatch(questParam)
            .then((res) => {
              if (res.data.success === 1) {
                this.dispatchOperation === 1 && this.$Message.success("添加排班操作成功！");
                this.dispatchOperation === 2 && this.$Message.success("修改排班操作成功！");
                this.isShowDispatchModal = false;
                this.getTableListData();
              } else {
                this.$Message.error(res.data.msg);
              }

              console.log(333); //  收到 fetchAddDispatch 请求后
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          this.$Message.warning("请填写完整的表单数据！");

          console.log(" nooooo "); //  发送 fetchAddDispatch 请求前
        }

      });
    },

    // 编辑备注点击确认
    submitNote(){
      const newTabeData = [...this.tableData];
      newTabeData[this.remarksIndex].remarks = this.remarks;
      this.tableData = newTabeData;
        fetchNote([...this.tableData])
        .then((res) => {
          if (res.data.success === 1) {        
          this.$Message.success("操作成功"); 
          } else {
            console.log([...this.tableData]);
            this.$Message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    clickSelectMachine() {
      this.isShowMachineModal = true;
    },

    removeMachineTag(event, name) {
      const index = this.dispatchFormObj.machineNo.indexOf(name);
      this.dispatchFormObj.machineNo.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/common.scss";

.page-device-view {
}
</style>
<style lang="scss">
#page-device-view {
  .dispatch-modal {
    .ivu-tag {
      background-color: #fff;
      border: 1px #57a3f3 solid;
    }
  }
}
</style>
